import { graphql } from "gatsby";
import React from "react";
import Richtext from "../components/atoms/Richtext/Richtext";
import Layout from "../components/global/Layout/Layout";
import { formatPageData } from "../components/global/Layout/utils";
import SEO from "../components/global/SEO/SEO";
import PagePadding from "../components/templates/PagePadding/PagePadding";
import Section from "../components/templates/Section/Section";
import {
  PageContent,
  PageData,
  SiteLocale,
  SiteLocales,
  SiteSettings,
  SiteTranslations,
} from "../templates/Page/types";
import { sortLocales } from "../templates/Page/utils";

interface Props {
  data: {
    sanitySetting: SiteSettings;
    allSanityLanguage: SiteLocales;
    allSanityTranslation: SiteTranslations;
    sanityPage: PageData;
  };
  uri: string;
  pageContext: PageContent;
}

const NotFoundPage = (props: Props) => {
  const site = props.data.sanitySetting;
  const locales = props.data.allSanityLanguage.edges
    .map((x) => x.node)
    .sort(sortLocales);
  const page = formatPageData(site, props.data.sanityPage);

  const renderLocale = (locale: SiteLocale) => {
    return locale.notFoundPage ? (
      <div key={locale.id} className="not-found__language">
        <h2>{locale.notFoundPage.title}</h2>
        <Richtext blocks={locale.notFoundPage._rawText} />
      </div>
    ) : null;
  };

  return (
    <Layout header={page.header} footer={page.footer}>
      <SEO
        locale={page.locale}
        title="404"
        titleSuffix={site.titleSuffix}
        description="This page does not exist (anymore)."
        favicon={site.favicon.asset.url}
      />
      <Section>
        <PagePadding className="not-found">
          <h1 className="not-found__headline">404</h1>
          <div className="not-found__languages">
            {locales.map((x) => renderLocale(x))}
          </div>
        </PagePadding>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    ...settings
    ...languages
    ...translations
    ...pageByLocale
  }
`;

export default NotFoundPage;
